import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmationBox(props: any) {
  const { confirmationBox, handleConfirmationBox, deleteCarousalVideoById } = props;
  return (
    <div>
      <Dialog
        open={!!confirmationBox}
        onClose={() => handleConfirmationBox(0)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Delete the Carousel Video
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirmationBox(0)} color="secondary" variant="contained">
            No
          </Button>
          <Button onClick={() => {
            deleteCarousalVideoById(confirmationBox)
          }} color="primary" variant="contained" autoFocus>
            yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
