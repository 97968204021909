import React from "react";
import ReactPlayer from "react-player/lazy";
import ReactCarousel from "react-multi-carousel";
import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { carouselBucketURL } from "../../server/constants";
import imgQuote from './images/img-quote.svg';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: '#F7F9FB',
      boxShadow: 'none',
      borderRadius: 0,
    },
    media: {
      height: 269,
      // [ theme.breakpoints.down("sm") ]: {
      //   height: 234,
      // },
    },
    sectionPT: {
      paddingTop: '20%',
    },
  }),
);
export default function Carousel(props: any) {
  const {videos} = props;
  const rSymbolRegex =  /\#[\\R]+/g;
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false);
  const classes = useStyles();
  const responsive = {
    desktop: {
      breakpoint: {max: 5000, min: 1601},
      items: 4,
      slidesToSlide: 3, // optional, default to 1.
    },
    desktopmini: {
      breakpoint: {max: 1600, min: 1024},
      items: 3,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet: {
      breakpoint: {max: 1024, min: 767},
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: {max: 766, min: 0},
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const handleClickPreview = () => {
    setIsPlaying(true);
  };

  const regexReplace = (text: any) => {
    if(!text.match(rSymbolRegex)) {
      return text;
    }
    const yourStringWithHtmlInIt = text.replace(rSymbolRegex, `<sup>®</sup>`);
    return <div dangerouslySetInnerHTML={{ __html: yourStringWithHtmlInIt }} />;
  }

  if (props.hasOwnProperty("videos")) {
    return (
      <ReactCarousel
        arrows={true}
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={false}
        autoPlay={false}
        autoPlaySpeed={15000}
        keyBoardControl={true}
        customTransition="transform 300ms ease-in-out"
        transitionDuration={500}
        containerClass="carousel-container"
        deviceType={"mobile"}
        itemClass="carousel-item-padding-40-px sectionPT"
      >
        {
          videos.map((item: any) => {
            const videoUrl: string = item.url;
            return (
              <div key={item.title}>
                <Card className={classes.root}>
                  <CardActionArea>
                    <CardMedia className={classes.media}>
                      <ReactPlayer
                        style={{width: "100%", height: "269px"}}
                        controls={true}
                        width="100%"
                        height="269px"
                        url={videoUrl}
                        config={{
                          vimeo: {playerOptions: {title: false}}
                        }}
                        light={false}
                        onClickPreview={handleClickPreview}
                        playing={isPlaying}
                      />
                    </CardMedia>
                    <CardContent className="alternateBg" style={{float: 'left', width: '100%', padding: '15px 30px'}}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="caption" color="primary">{regexReplace(item.strategyTestimonial)}</Typography>
                        </Grid>
                        <Grid item xs={8} md={8}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Typography variant="h4" color="primary" className="mb0 floatLeft w100">{regexReplace(item.name)}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="caption" color="primary">{regexReplace(item.designation)}</Typography>
                            </Grid>

                            <Grid item xs={12}>
                              <img width="48px" height="48px" src={imgQuote} className="mt10" alt="quote"/>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography variant="caption" color="primary">{regexReplace(item.title)}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4} md={4}>
                          <img style={{
                            float: 'right',
                            width: 100,
                            height: 100,
                            borderRadius: '50%',
                            border: '3px solid #D6A122',
                          }} src={`${carouselBucketURL}/${item.image}`} alt="Video Client Thum"/>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            );
          })
        }
      </ReactCarousel>
    );
  } else {
    return (<></>);
  }
}
