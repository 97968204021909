import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import TableRow from '@material-ui/core/TableRow';
import { carouselBucketURL, externalServerUrl } from "../../server/constants";
import AddEditCarousel from "./AddEditCarousel";
import axios from "axios";
import Carousel from "../Carousel";
import { useAuth } from "../../lib/useAuth";
import ConfirmationBox from "./ConfirmationBox";
import Avatar from '@material-ui/core/Avatar';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import DialogContent from '../dialog/DialogContent';
import DialogHeader from '../dialog/DialogHeader';
import { consoleLog } from '../../utils/commonUtils';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    backgroundColor: '#dfdce8'
  }
});


export default function CustomizedDialogs(props: any) {
  const { user } = useAuth();
  const isLoggedIn: any = user && user.isLoggedIn;
  const [videos, setVideos] = React.useState<any>([]);
  const { carouselId } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openCarouselEditor, setOpenCarouselEditor] = React.useState(false);
  const [individualCarousalData, setIndividualCarousalData] = React.useState<any>({});
  const [confirmationBox, setConfirmationBox] = React.useState(0);
  const [snackbarOpen, setSnackbarOpen] = React.useState(true);
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState<string | any>("success");

  const handleClickOpen = () => {
    setOpen(true);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmationBox = (videoId: number) => {
    if (videoId) {
      setConfirmationBox(videoId);
    } else {
      setConfirmationBox(0);
    }
  };

  React.useEffect(() => {
    getCarouselVideos(carouselId).then(()=>{});
  }, [carouselId]);

  const getCarouselVideos = async (carouselId: number) => {
    // make sure we ID
    if (!carouselId) {
      return;
    }

    // fetch data
    const response = await axios.get(`${externalServerUrl}/api/carousel/listVideos`, { params: { carouselId: carouselId } });
    if (!response || response.data.length === 0) {
      return;
    }

    // set data
    setVideos(response.data || []);
  };

  const deleteCarousalVideoById = async (videoId: number) => {
    const response = await axios.delete(
      `${externalServerUrl}/api/carousel/deleteCarousalVideo`,
      { params: { videoId: videoId } }
    );

    if (!response) {
      return;
    }

    await getCarouselVideos(carouselId);
    setConfirmationBox(0);
    setSnackbarOpen(true);
    setSeverity('success');
    setMessage('Video Deleted successfully');
  };

  const handleSnackBarClose = (event?: React.SyntheticEvent, reason?: string) => {
    consoleLog(event);
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleCarouselEditorClose = () => {
    setOpenCarouselEditor(false);
  };

  const SortableItem = SortableElement((value: any) => {
    let video = value && value.value;
    return (
      <TableRow className="tableRow" key={video.id}>
        <TableCell component="th">
          {video && video.title}
        </TableCell>
        <TableCell component="th">
          {video && video.name}
        </TableCell>
        <TableCell component="th">
          {video && video.url}
        </TableCell>
        <TableCell component="th">
          <Avatar src={`${carouselBucketURL}/${video && video.image}`} style={{
            width: 45, height: 45, borderRadius: '50%', display: 'inline-block'
          }}/>
        </TableCell>

        <TableCell align="center">
          <IconButton aria-label="Info" color="primary" onClick={() => {
            setIndividualCarousalData(video);
            setOpenCarouselEditor(true);
          }}>
            <EditIcon/>
          </IconButton>
          <IconButton aria-label="Info" color="primary" onClick={() => {
            handleConfirmationBox(video && video.id);
          }}>
            <DeleteIcon className="floatRight colorRed"/>
          </IconButton>
        </TableCell>
      </TableRow>
    )
  });
  const ReactSortableContainer: any = SortableContainer(({ children }: any) => {
    return <TableBody className={classes.table} aria-label="simple table">{children}</TableBody>;
  });

  const onSortEnd = async ({ oldIndex, newIndex }: any) => {
    let oldVideo: any = videos[ oldIndex ];
    let newVideo: any = videos[ newIndex ];
    if (!oldVideo || !newVideo) {
      return;
    }

    let payload: any = {
      videoAtNewIndex: newVideo,
      videoAtOldIndex: oldVideo
    };

    await axios.post(`${externalServerUrl}/api/carousel/updateCarouselVideoPosition`, payload);
    await getCarouselVideos(carouselId);
  };

  return (
    <div>
      {isLoggedIn &&
        <Button className="floatRight mb20 " size="small" variant="contained" color="primary" onClick={handleClickOpen}>
          Edit Carousel
        </Button>}
      {!!message && <Snackbar open={snackbarOpen} autoHideDuration={4000} onClose={handleSnackBarClose}
                              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleSnackBarClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>}
      <Dialog onClose={handleClose} maxWidth="lg" aria-labelledby="customized-dialog-title" open={open}>
        <DialogHeader onClose={handleClose}>
          Carousel Videos <Button className="ml30" size="small" variant="contained" color="primary" onClick={() => {
          setIndividualCarousalData({});
          setOpenCarouselEditor(true);
        }}>
          Add New Video
        </Button>
        </DialogHeader>
        <DialogContent dividers>

          <TableContainer component={"div"}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead}>Title</TableCell>
                  <TableCell className={classes.tableHead}>Name</TableCell>
                  <TableCell className={classes.tableHead}>URL</TableCell>
                  <TableCell className={classes.tableHead}>Image</TableCell>
                  <TableCell align="center" className={classes.tableHead} style={{ minWidth: 150 }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <ReactSortableContainer
                onSortEnd={onSortEnd}
                shouldCancelStart={(e: any) => {
                  if (e.target.tagName === "path") {
                    return true;
                  }
                }}>
                {videos.map((video: any, index: any) => {
                  return (
                    <SortableItem key={`item-${video}`} index={index} value={video}/>
                  )
                })}
              </ReactSortableContainer>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
      <AddEditCarousel
        individualCarousalData={individualCarousalData}
        openCarouselEditor={openCarouselEditor}
        handleCarouselEditorClose={handleCarouselEditorClose}
        carouselId={carouselId}
        getCarouselVideos={(carouselId: number) => getCarouselVideos(carouselId)}
      />
      <ConfirmationBox
        confirmationBox={confirmationBox}
        handleConfirmationBox={handleConfirmationBox}
        deleteCarousalVideoById={(videoId: number) => deleteCarousalVideoById(videoId)}
      />
      <div className="clearFix"/>
      <Carousel videos={videos}/>
    </div>
  );
}
